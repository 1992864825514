import pick from 'lodash/pick';
import React, { useMemo } from 'react';
import { object, string } from 'yup';

import { Accordion, Box, Separator, TextInput } from '@attentive/picnic';

import { useUpdateIntegrationSettingsWithSpecialCasesMutation } from '../../../api';
import { useIntegrationContext } from '../../../hooks';
import { IntegrationFieldType, IntegrationFormFeatureValues } from '../../../types';
import { generateGraphqlId } from '../../../utils/gql-id';
import { parseJson } from '../../../utils/parse-json';
import { IntegrationField } from '../../IntegrationField';
import { IntegrationForm } from '../../SetupConnectAndConfigure/IntegrationForm';
import { SubmitButton } from '../../SetupConnectAndConfigure/SubmitButton';
import { FixedFormFooter } from '../FixedFormFooter';
import { AdditionalProperties } from '../controls/AdditionalProperties';
import { CreativeSpecificLists } from '../controls/CreativeSpecificLists';
import { CreativeSpecificSources } from '../controls/CreativeSpecificSources';
import { AccordionHeader, Header } from '../controls/headers';

function OmetriaSettingsFormNext({ onComplete }: { onComplete: () => void }) {
  const { integrationDetailValues } = useIntegrationContext();

  const validationConfig = {
    fields: {
      apiKey: { required: true },
      listId: { required: true },
    },
  };

  // pick out only the fields/features that this form actually shows from
  // the current state of what's in the backend. This ensures we only submit
  // the values that the form actually shows inputs for.
  const rawInitialValues: Record<string, string | IntegrationFormFeatureValues> = pick(
    integrationDetailValues || {},
    [
      'apiKey',
      'collection',
      'listId',
      'creativeSpecificSources',
      'creativeSpecificLists',
      'customProperties',
      'POSTBACK_EMAILS',
      'POST_WITH_PHONE',
      'FEATURE_POST_SUBSCRIBER_OPT_OUT',
    ]
  );

  const initialValues = {
    ...rawInitialValues,
    // Server returns a record, but because we need to preserve ordering while
    // the user is editing these values, we need to convert it into entries.
    // The server also returns Creative internalIds and can't handle gql ids, so
    // we need to manually convert them as everything downstream of this uses
    // graphql. We essentially do the reverse of all of this when saving
    // back to the server. We translate ids back to internalIds and convert
    // the entries into an object.
    creativeSpecificSources: useMemo(() => {
      const data = parseJson<Record<string, string[]>>(
        rawInitialValues.creativeSpecificSources as string,
        {}
      );
      const encodedData = Object.entries(data).map(([key, internalIds]) => [
        key,
        internalIds.map((internalId) => generateGraphqlId('Creative', internalId)),
      ]);
      return JSON.stringify(encodedData);
    }, [rawInitialValues]),
    // Same reasoning as `creativeSpecificSources`
    creativeSpecificLists: useMemo(() => {
      const data = parseJson<Record<string, string[]>>(
        rawInitialValues.creativeSpecificLists as string,
        {}
      );
      const encodedData = Object.entries(data).map(([key, internalIds]) => [
        key,
        internalIds.map((internalId) => generateGraphqlId('Creative', internalId)),
      ]);
      return JSON.stringify(encodedData);
    }, [rawInitialValues]),
    // Same reasoning as `creativeSpecificSources`
    customProperties: useMemo(() => {
      const data = parseJson<Record<string, string>>(
        rawInitialValues.customProperties as string,
        {}
      );
      return JSON.stringify(Object.entries(data));
    }, [rawInitialValues]),
  };

  return (
    <IntegrationForm
      loadingText="Saving"
      initialValues={initialValues}
      validationConfig={validationConfig}
      validationSchema={object().shape({
        creativeSpecificLists: string()
          .test('creativeSpecificLists-uniqueness', 'Lists can only be selected once', (value) => {
            const entries = parseJson<Array<[string, string[]]>>(value, []);
            const lists = new Set(entries.map(([list]) => list));

            return lists.size === entries.length;
          })
          .test(
            'creativeSpecificLists-nonempty',
            'Each list must have at least one selected sign-up unit.',
            (value) => {
              const entries = parseJson<Array<[string, string[]]>>(value, []);

              return entries.every(([, creatives]) => creatives.length > 0);
            }
          )
          .test(
            'creativeSpecificLists-creatives-uniqueness',
            'Sign-up units can only be assigned to a single list.',
            (value) => {
              const entries = parseJson<Array<[string, string[]]>>(value, []);
              const creatives = entries.flatMap(([, cIds]) => cIds);

              return new Set(creatives).size === creatives.length;
            }
          ),
        creativeSpecificSources: string()
          .test(
            'creativeSpecificSources-nonempty',
            'Each source must have at least one selected sign-up unit.',
            (value) => {
              const entries = parseJson<Array<[string, string[]]>>(value, []);

              return entries.every(([, creatives]) => creatives.length > 0);
            }
          )
          .test(
            'creativeSpecificSources-creatives-uniqueness',
            'Sign-up units can only be assigned to a single list.',
            (value) => {
              const entries = parseJson<Array<[string, string[]]>>(value, []);
              const creatives = entries.flatMap(([, cIds]) => cIds);

              return new Set(creatives).size === creatives.length;
            }
          ),
      })}
      mutation={useUpdateIntegrationSettingsWithSpecialCasesMutation}
      onComplete={onComplete}
      // Height of the fixed footer + standard spacing
      css={{ mb: '114px' }}
    >
      <Box css={{ width: '100%' }}>
        <Header
          title="Default configuration"
          subtitle="Configure default settings for the app."
          css={{ mb: '$space4' }}
        />
        <IntegrationField
          name="apiKey"
          type={IntegrationFieldType.PASSWORD}
          label="API Key"
          placeholder="Enter API Key"
          required={true}
          css={{ width: '100%', mb: '$space4' }}
        />
        <IntegrationField
          name="collection"
          type={IntegrationFieldType.TEXT}
          label="Collection"
          placeholder="Enter Collection"
          required={true}
          css={{ width: '100%', mb: '$space4' }}
        />
        <IntegrationField
          name="listId"
          type={IntegrationFieldType.TEXT}
          label="List IDs"
          placeholder="Enter List IDs"
          required={true}
          css={{ width: '100%' }}
        />
      </Box>

      <Separator css={{ my: '$space8' }} />

      <Header
        title="Optional settings"
        subtitle="The below settings are optional to help with additional configuration of the Klaviyo integration."
        css={{ mt: 0 }}
      />

      <Accordion collapsible type="single" variant="neutral" css={{ width: '100%' }}>
        <Accordion.Item value="list-management">
          <AccordionHeader
            title="Sign-up unit list management"
            subtitle="Specify the list destination for emails from specific sign-up units. The default will be used in the event a specific email list is not designated."
          />
          <Accordion.Content>
            <CreativeSpecificLists
              name="creativeSpecificLists"
              listControl={({ value, onChange }) => (
                <TextInput
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                />
              )}
            />
          </Accordion.Content>
        </Accordion.Item>
      </Accordion>

      <Accordion collapsible type="single" variant="neutral" css={{ width: '100%' }}>
        <Accordion.Item value="source-management">
          <AccordionHeader
            title="Sign-up unit source management"
            subtitle="Specify the source given to emails from specific sign-up units. The default will be used if a sign-up unit is not given a custom source."
          />
          <Accordion.Content>
            <CreativeSpecificSources name="creativeSpecificSources" />
          </Accordion.Content>
        </Accordion.Item>
      </Accordion>

      <Accordion collapsible type="single" variant="neutral" css={{ width: '100%' }}>
        <Accordion.Item value="data-sync">
          <AccordionHeader
            title="Sync additional data to Ometria"
            subtitle="Select additional data to sync to Ometria."
          />
          <Accordion.Content>
            <AdditionalProperties vendorName="Ometria" name="customProperties" />
          </Accordion.Content>
        </Accordion.Item>
      </Accordion>

      <FixedFormFooter>
        <SubmitButton text="Save changes" />
      </FixedFormFooter>
    </IntegrationForm>
  );
}

export { OmetriaSettingsFormNext };
