import pick from 'lodash/pick';
import React, { useMemo } from 'react';
import { object, string } from 'yup';

import { Accordion, Box, Icon } from '@attentive/picnic';

import { useUpdateIntegrationSettingsWithSpecialCasesMutation } from '../../../api';
import { useIntegrationContext } from '../../../hooks';
import { IntegrationFieldType, IntegrationFormFeatureValues } from '../../../types';
import { generateGraphqlId } from '../../../utils/gql-id';
import { parseJson } from '../../../utils/parse-json';
import { IntegrationField } from '../../IntegrationField';
import { IntegrationForm } from '../../SetupConnectAndConfigure/IntegrationForm';
import { SubmitButton } from '../../SetupConnectAndConfigure/SubmitButton';
import { FixedFormFooter } from '../FixedFormFooter';
import { CreativeSpecificSources } from '../controls/CreativeSpecificSources';
import { AccordionHeader, Header } from '../controls/headers';

function HubspotSettingsFormNext({
  isSuperUser,
  onComplete,
}: {
  isSuperUser: boolean;
  onComplete: () => void;
}) {
  const { integrationDetailValues } = useIntegrationContext();

  // pick out only the fields/features that this form actually shows from
  // the current state of what's in the backend. This ensures we only submit
  // the values that the form actually shows inputs for.
  const rawInitialValues: Record<string, string | IntegrationFormFeatureValues> = pick(
    integrationDetailValues || {},
    ['creativeSpecificSources', 'sendPhone']
  );

  const initialValues = {
    ...rawInitialValues,
    // Server returns a record, but because we need to preserve ordering while
    // the user is editing these values, we need to convert it into entries.
    // The server also returns Creative internalIds and can't handle gql ids, so
    // we need to manually convert them as everything downstream of this uses
    // graphql. We essentially do the reverse of all of this when saving
    // back to the server. We translate ids back to internalIds and convert
    // the entries into an object.
    creativeSpecificSources: useMemo(() => {
      const data = parseJson<Record<string, string[]>>(
        rawInitialValues.creativeSpecificSources as string,
        {}
      );
      const encodedData = Object.entries(data).map(([key, internalIds]) => [
        key,
        internalIds.map((internalId) => generateGraphqlId('Creative', internalId)),
      ]);
      return JSON.stringify(encodedData);
    }, [rawInitialValues]),
    sendPhone: rawInitialValues.sendPhone === 'true' ? 'true' : 'false',
  };

  return (
    <IntegrationForm
      loadingText="Saving"
      initialValues={initialValues}
      validationSchema={object().shape({
        creativeSpecificSources: string().test(
          'creativeSpecificSources-nonempty',
          'Each source must have at least one selected sign-up unit.',
          (value) => {
            const entries = parseJson<Array<[string, string[]]>>(value, []);

            return entries.every(([, creatives]) => creatives.length > 0);
          }
        ),
      })}
      mutation={useUpdateIntegrationSettingsWithSpecialCasesMutation}
      onComplete={onComplete}
    >
      <Header
        title="Optional settings"
        subtitle="The below settings are optional to help with additional configuration of the HubSpot integration."
        css={{ mt: 0 }}
      />

      <Accordion collapsible type="single" variant="neutral" css={{ width: '100%' }}>
        <Accordion.Item value="source-management">
          <AccordionHeader
            title="Sign-up unit source management"
            subtitle="Specify the source given to emails from specific sign-up units. The default will be used if a sign-up unit is not given a custom source."
          />
          <Accordion.Content>
            <CreativeSpecificSources name="creativeSpecificSources" />
          </Accordion.Content>
        </Accordion.Item>
      </Accordion>

      {isSuperUser && (
        <Accordion collapsible type="single" variant="neutral" css={{ width: '100%' }}>
          <Accordion.Item value="data-sync">
            <AccordionHeader
              title="Sync additional data to Hubspot"
              subtitle="Select additional data to sync to Hubspot."
            />
            <Accordion.Content>
              <IntegrationField
                name="sendPhone"
                type={IntegrationFieldType.CHECKBOX}
                label={
                  <Box css={{ display: 'flex', alignItems: 'center' }}>
                    Phone number
                    <Icon name="ShieldPerson" size="medium" css={{ ml: '$space2' }} />
                  </Box>
                }
                required={false}
                css={{ width: '100%' }}
              />
            </Accordion.Content>
          </Accordion.Item>
        </Accordion>
      )}

      <FixedFormFooter>
        <SubmitButton text="Save changes" />
      </FixedFormFooter>
    </IntegrationForm>
  );
}

export { HubspotSettingsFormNext };
