import React from 'react';

import { Accordion, Box, Heading, PicnicCss, Text } from '@attentive/picnic';

function Header({ title, subtitle, css }: { title: string; subtitle: string; css?: PicnicCss }) {
  return (
    <Box css={css}>
      <Heading variant="md">{title}</Heading>
      <Text variant="caption" css={{ fontWeight: '$regular' }}>
        {subtitle}
      </Text>
    </Box>
  );
}

function AccordionHeader({ title, subtitle }: { title: string; subtitle: string }) {
  return (
    <Accordion.Header>
      <Box>
        {title}
        <Text variant="caption" css={{ fontWeight: '$regular' }}>
          {subtitle}
        </Text>
      </Box>
    </Accordion.Header>
  );
}

export { Header, AccordionHeader };
