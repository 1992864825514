import React from 'react';

import { IntegrationDetailsResponse, VendorKey } from '../../../types';
import { SettingsStandard } from '../SettingsStandard';

function HubspotSettingsFormLegacy({
  onComplete: onComplete,
}: {
  onComplete?: (integrationDetails: IntegrationDetailsResponse) => void;
}) {
  return <SettingsStandard vendorKey={VendorKey.HUBSPOT} onComplete={onComplete} fields={[]} />;
}

export { HubspotSettingsFormLegacy };
